<template>
  <div class="aSelectfield field">

    <!-- mask to close field if user click outside the field -->
    <div
        class="inputmask"
        v-if="isOptionsListOpen"
        @click="closeWithoutSelection"
    ></div>

    <div class="selectfield">
      <label :for="fieldname"><span v-html="label"></span><span v-if="isRequired" class="req" tabindex="-1">&nbsp;*</span></label>

      <div class="dropdown" :class="{'open': isOptionsListOpen}">

        <input
            type="text"
            autocomplete="off"
            :id="fieldname"
            v-model="textInField"
            @input="userChangedInput"
            :placeholder = "placeholder"
            :class="{'hasSuccess': isInSuccess, 'hasError': isInError, 'isLoading': isLoading}"
            @keyup.prevent.down="keyPressedDown"
            @keyup.prevent.up = "keyPressedUp"
            @keyup.prevent.enter = "keyPressedEnter"
            @keydown.tab="tabPressed"
            :disabled="isDisabled"
            aria-autocomplete="list"
            size="30"
        >

        <!-- Toggler -->
        <div class="toggle" @click="toggleListOpeness()" :class="{'active': isOptionsListOpen}">
					<span class="arrow-up" v-show="isOptionsListOpen">
						<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
                 viewBox="0 0 36 36" xml:space="preserve">
						<g>
							<polygon points="0,24 18,6 36,24 31,28 18,15 5,28"/>
						</g>
					</svg>
				</span>
          <span class="arrow-down" v-show="!isOptionsListOpen">
					<svg height="20px" width="20px" version="1.1" id="Capa_1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink"
               viewBox="0 0 36 36" xml:space="preserve">
					<g>
						<polygon  points="5,8 18,21 31,8 36,12 18,30 0,12"/>
					</g>
				</svg>
			</span>
        </div>

        <!-- Options list -->
        <ul
            v-show="isOptionsListOpen"
            class="options-list"
            style="overflow-y: auto; scroll-behavior: smooth;"
            tabindex="-1"
            @keydown.tab="tabPressed"
            @keyup.prevent.enter="keyPressedEnter"
        >
          <li
              v-for="(option, index) in optionsList"
              :key="index"
              @click="selectThisOption(option, index)"
              class="liSelect"
              :class="{'active': currentActiveOption === index}"
              tabindex="0"
              role="listitem"
          >{{option.description}}</li>
          <li v-show="noResult" @click="closeWithoutSelection">{{$t('inputLabel.noResult')}}</li>
        </ul>

      </div>
    </div>

    <div class="errorMessage" v-if="isInError">{{errorMessage}}</div>
    <div class="helper" role="text" :aria-label="'More info for ' + label + ' field'"><span v-html="helper"></span></div>

  </div>
</template>

<!-- ================================================================================== -->

<script>
import FormFields from "../mixins/FormFields";
import customLog from "../mixins/CustomLog";
import config from "../config";

export default {
  name:"SelectApi",
  mixins: [
    customLog,
    FormFields,
  ],
  props: {
    label : {
      type: String,
      required: true, /* false in meta only */
    },
    dataSource: {
      type: String,
      required: true,
    },
    caractersBeforeOpenList : {
      type: Number,
      required: false,
      default: 3
    },
    autocomplete: {
      type: Boolean,
      required: true,
    },
    isDisabled: {
      type: Boolean,
      required: false,
      default: false,
    }
  },

  data(){
    return {
      isOptionsListOpen: false,
      optionsList: [],
      optionsFullList: [],
      displayedInTheField: null,
      config: config,
      currentActiveOption: null,
      noResult: false,
      keyDownWasPressed: false,
      tabKeyWasPressed: false,
    }
  },

  computed: {
    textInField: {
      get(){
        return this.displayedInTheField;
      },
      set(newVal){
        this.displayedInTheField = newVal;
      }
    },
  },

  methods: {

    keyPressedDown(){
      this.keyDownWasPressed = true;
      this.tabKeyWasPressed = false;

      if(this.isOptionsListOpen && this.currentActiveOption < this.optionsList.length -1){
        this.downOneItem();
      }
      if(!this.isOptionsListOpen){
        this.makeNewOptionsList(false);
        this.isOptionsListOpen = true;
        this.currentActiveOption = 0;
      }

    },

    tabPressed(e){

      this.tabKeyWasPressed = true;
      this.keyDownWasPressed = false;

      if(this.isOptionsListOpen && this.currentActiveOption < this.optionsList.length){

        if(e.shiftKey) {
          this.upOneItem();
        }else{
          this.downOneItem();
        }

      }else if(!this.isOptionsListOpen && !e.shiftKey){
        this.makeNewOptionsList(false);
        this.isOptionsListOpen = true;
        this.currentActiveOption = 0;
      }

    },

    keyPressedUp(){
      if(this.isOptionsListOpen && this.currentActiveOption > 0){
        this.upOneItem();
      }else{
        this.closeOptionsList();
        this.currentActiveOption = null;
      }
    },

    keyPressedEnter(){
      if(this.isOptionsListOpen){
        this.log("Enter when list is open", 'low');
        this.selectThisOption(this.optionsList[this.currentActiveOption], this.currentActiveOption);
      }
    },

    upOneItem(){

      const { scrollX, scrollY } = window;

      if(this.currentActiveOption > 0){
        this.currentActiveOption -= 1;
      }else if (this.currentActiveOption <= 0){
        this.closeWithoutSelection();
      }

      if(this.currentActiveOption >= 5){
        let liElement = document.getElementsByClassName("liSelect");
        liElement[this.currentActiveOption].scrollIntoView(false);
        window.scrollTo(scrollX, scrollY); // makes sure the window doesn't scroll
      }
    },

    downOneItem(){

      const { scrollX, scrollY } = window;

      if (this.currentActiveOption < this.optionsList.length-1){
        this.currentActiveOption += 1;
      }else if (this.currentActiveOption >= this.optionsList.length-1 && this.tabKeyWasPressed === true){
        this.closeWithoutSelection();
      }

      if(this.currentActiveOption >= 5 && this.keyDownWasPressed === true){
        console.log("scroll");
        let liElement = document.getElementsByClassName("liSelect");
        liElement[this.currentActiveOption].scrollIntoView(false);
        window.scrollTo(scrollX, scrollY); // makes sure the window doesn't scroll
      }
    },

    selectThisOption(option, index){
      this.log(`User choose item ${option.id} - ${option.description}`, 'low');
      this.displayedInTheField = option.description;
      this.currentActiveOption = index;
      this.closeOptionsList(option.id);
    },

    closeWithoutSelection(){
      this.log("Closing the optionList without selection", 'low');
      this.textInField = "";
      this.closeOptionsList();
      this.currentActiveOption = null; /* maybe could be inchanged instead? */
      this.runValidationProcess();
      this.$emit('update:modelValue', null);
    },

    toggleListOpeness(){
      this.isOptionsListOpen = !this.isOptionsListOpen;
      if(this.isOptionsListOpen){
        this.displayedInTheField = "";
        this.makeNewOptionsList(this.autocomplete);
      }
    },

    emptyAndCloseList(){
      this.optionsList = [];
      this.closeOptionsList();
    },

    userChangedInput(){
      /* Refresh list of options */
      /* User left no caracters => options list is empty */
      if(this.displayedInTheField.length === 0){
        this.emptyAndCloseList();
      }
      /* User type some caracters => options list matching strings */
      if(this.displayedInTheField.length >= this.caractersBeforeOpenList){
        this.makeNewOptionsList(this.autocomplete);
        if(!this.isOptionsListOpen){ this.openOptionsList(); }
      }
      /* Display "no result" instead of nothing */
      this.noResult = (this.displayedInTheField.length >= this.caractersBeforeOpenList && this.optionsList.length < 1);
    },

    makeNewOptionsList(bool){
      /* If autocomplete is true, it's a partial list */
      if(bool){
        let userInput = this.displayedInTheField.toLowerCase();
        this.optionsList = this.optionsFullList.filter(option => option.description.toLowerCase().includes(userInput));
      }
      /* If autocomplete is false, it's a full list */
      if(!bool){
        this.optionsList = this.optionsFullList;
      }
    },

    openOptionsList(){
      /* User open the option list */
      this.log("Opening option list", 'low');
      /* remove previous options */
      this.optionsList = [];
      /* recreate new options list */
      this.makeNewOptionsList(this.autocomplete);
      /* DOM open the list */
      this.isOptionsListOpen = true;
    },

    closeOptionsList(id){
      /* User leave the option list */
      this.noResult = false;
      this.log("Closing option list", 'low');
      this.isOptionsListOpen = false;
      this.runValidationProcess(id);
    },

    isThisFieldValid(){
      /* What is a valid answer ? An answer that is exactly in the list */
      let validOptionsList = [];
      for(let item in this.optionsFullList){
        validOptionsList.push(this.optionsFullList[item].description.toLowerCase());
      }
      return validOptionsList.includes(this.displayedInTheField.toLowerCase());
    },

    sendDataToForm(id){
      this.log(`SelectApi transmitting ${id} to parent`, 'low');
      this.$emit('update:modelValue', id);
    },

    sendValidityOfTheFieldToTheForm(bool){
      this.$emit('runCheck', {fieldname: this.fieldname, valid: bool});
    },

    whatToDoInCaseFieldIsValidated(id){
      this.sendDataToForm(id);
      this.giveSuccess();
      this.sendValidityOfTheFieldToTheForm(true);
    },

    whatToDoInCaseFieldIsNotValidated(){
      this.giveError();
      this.errorMessage = this.$t('inputsTexts.dropdown.missingData');
      this.sendValidityOfTheFieldToTheForm(false);
    },

    runValidationProcess(id){
      this.removeSuccessOrError();
      this.isThisFieldValid() ? this.whatToDoInCaseFieldIsValidated(id) : this.whatToDoInCaseFieldIsNotValidated();
    },

    apiForDropdownIsSuccessful(data){
      this.log("Data gathered from API", 'low');
      this.log(data);
      /* Remove empty descriptions */
      this.optionsList = data.filter(item => {
        if(this.dataSource === "countries-allowed"){
          return item.description != '' && item.description != null && item.countrytypereference.code === "ConsumerAddress";
        }else{
          return item.description != '' && item.description != null;
        }
      });
      this.optionsFullList = this.optionsList; /* untouched list */
    },

    apiForDropdownHasFailed(error){
      this.log("apiForDropdownHasFailed", 'alert');
      this.log(error);
      if(error.response.status === 503){
        this.setNotLoading();
        this.$emit('maintenanceDetected');
      }else{
        console.log("Cannot get list from API");
        console.error(error);
      }
    },

    requestNewListFromAPI(){
      this.optionsList = [{id:0, description: "Lorem ipsum"}];
      this.setLoading();
      this.axios.get(`/dropdown/${config.idlead}/${this.dataSource}`)
          .then(resp => {
            this.apiForDropdownIsSuccessful(resp.data);
          })
          .catch(error => {
            this.apiForDropdownHasFailed(error);
          })
          .then(()=>{
            this.log(`List from API are put in place in ${this.fieldname}.`, 'low');
          });
      this.setNotLoading();
    },

  },
  mounted(){
    this.requestNewListFromAPI();

  },
  watch: {
    /* If locale change, must reload the list to take lang into account */
    '$store.getters.getLocale': function(/* newLoacle, oldLocale */){
      this.requestNewListFromAPI();
    },
  },
}
</script>

<!-- ================================================================================== -->

<style lang="scss" scoped>
</style>
