export default {
	data () {
		return {

			/*LANGUAGE BUTTON*/
			/* can be 'language' or 'country' */

			modelLangButton: 'language',

			/* For CLIENT in GDPR, see the translation files */

			/* FIRST PAGE */
			/* usual values: 'landing', 'instructions', 'form1' */
			firstPageOfThisForm: 'form1',

			/* For P&G */
			/* If true, allow OneTrust tag and remove GDPR, Matomo, CookieFirst… */
			/* If false… Don't forget to update the IDs and CODES on mixins "ga" and "cf" for google analytics and cookie first */
			isPnGAction: true,

			/* SCRIPTS */
			thereIsFloodLightScriptInThisAction: false,

			/* BREADCRUMBS */
			displayBreadCrumbs: false,
			listOfCrumbsForThisAction: ['registration', 'store', 'form', 'survey', 'cashback'],
			 /* INFO Possibles values : 'info', 'registration', 'store', 'form', 'survey', 'summary', 'cashback' */
			
			/* DEFAULT LOCALE */
			/* Useful to force nl_NL instead of nl_BE */
			/* ! See in the i18n.js file */

			/* This is an "action coupon" with a coupon in EVERY participation */
			itsAnActionCoupon: false,
			/* If not, we may allow a coupon in the mail (if survey is filled) */
			aCouponCouldBeSent: false,
			/* Is there a survey at all ?*/
			thereIsASurveyInThisAction: false,

			/* Is there a visible counter? */
			counterIsVisible: false,

			/* REQUIRED FIELDS to UNLOCK BUTTONS */
			/* List of fields that must be valid for a specific button to unlock */
			mustBeValidForPage: {
				'btn_cashback_on_form1' : ['gender', 'firstName', 'lastName', 'email', ],
				'btn_cashback_on_form2' : ['file', 'conditions', 'idLeadTMD1', 'idLeadTMD2'],
				'btn_cashback_on_survey': ['idLeadTMD1', 'idLeadTMD2', 'idLeadTMD3'],
				'btn_cashback_on_printcoupon': ['conditionsCoupons'],
				/* INFO: it is possible to use an array to tell "if one of theses is filled, it is ok". Example below: */
				'example_with_array_of_possibles_value': ['firstName', ['idTitle', 'genre'], 'lastName'], // if idTitle OR genre is filled, the array is considered true.
			},
			/* REQUIRED FIELDS to UNLOCK THE FORM */
			/* Ultime check to see if something is missing despite the buttons check */
			/* Must never include optionnel answers like survey's */
			allRequiredFields: ['gender', 'firstName', 'lastName', 'email', 'conditions', 'idLeadTMD1', 'idLeadTMD2'],

			/* ! "countries" must not be required when the google API put the value in the field, as it is not validated by action api then. */
			/* TODO: find a way to make action api validate that field */

			/* ALL default fieldname that can be checked
					'idTitle',
					'gender',
					'firstName',
					'lastName',
					'email',
					'phoneNumber1',
					'IBAN', 
					'VATNumber',
					'street1',
					'houseNumber',
					'boxNumber',
					'postalCode',
					'city',
					'countries',
					'selectProduct',
					'purchaseDate',
					'idPOPRetailer',
					'freeRetailerName',
					'idLeadTMD1', 'idLeadTMD2',…
					'serialNumber',
					'file',
					'conditions',
					'uniqueCode'

			*/

			

		}
	},
}
